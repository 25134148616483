import { notification } from 'antd';
import { get, kebabCase } from 'lodash';
import { REF_BRANCH } from '../constants/common.constants';
import axios from './AxiosServices';


export const createNewBranch = async (branchName) => {
  return axios.post('repository/branches', {
    branch: kebabCase(branchName),
    ref: REF_BRANCH,
  }).catch((err) => {
    notification.error({
      message: get(err, 'response.data.message', 'ERROR'),
    });
  })
};

export const commit = async (branchName, commitTitle, commitDescription, commitActions) => {
  return axios.post('repository/commits', {
    branch: branchName,
    commit_message: commitTitle,
    actions: commitActions,
  }).catch((err) => {
    notification.error({
      message: get(err, 'response.data.message', 'ERROR'),
    });
  });
}

export const createMergeRequest = async (branch, title, description) => {
  return axios.post('merge_requests', {
    source_branch: branch,
    target_branch: REF_BRANCH,
    title,
    description,
  }).catch((err) => {
    notification.error({
      message: get(err, 'response.data.message', 'ERROR'),
    });
  })
}

