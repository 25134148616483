import logo from './logo.svg';
import './App.css';
import TokenContainer from './components/TokenContainer/TokenContainer';

import 'antd/dist/antd.css';
// import TranslationForm from './components/TranslationForm/TranslationForm';
import { Tabs } from 'antd';
import DashboardTab from './tabs/DashboardTab';
import CommonTab from './tabs/CommonTab';
import { useEffect, useState } from 'react';
import { COMMON_PROJECT_ID, DASHBOARD_PROJECT_ID } from './constants/common.constants';
import { setProjectId } from './services/AxiosServices';

function App() {
  const [project, setProject] = useState(COMMON_PROJECT_ID);
  useEffect(() => {
    setProjectId(project);
  }, [project]);
  return (
    <TokenContainer.Provider>
      <Tabs type="card" activeKey={project} onChange={setProject}>
        <Tabs.TabPane key={DASHBOARD_PROJECT_ID} tab="ServiceX Dashboard">
          <DashboardTab />
        </Tabs.TabPane>
        <Tabs.TabPane key={COMMON_PROJECT_ID} tab="Common">
          <CommonTab />
        </Tabs.TabPane>
      </Tabs>
    </TokenContainer.Provider>
  );
}

export default App;
