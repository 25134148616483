import React, { useEffect, useState } from "react";
import { setAxiosToken } from "../../services/AxiosServices";
import TokenModal from "../Modals/TokenModal";

const TokenContainer = React.createContext();

const GitProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setAxiosToken(token);
  }, [token]);

  return (
    <TokenContainer.Provider value={{ token, setToken, openTokenModal: () => setOpenModal(true) }}>
      {children}
      <TokenModal
        visible={openModal}
        onOk={(token) => {
          setToken(token);
          setOpenModal(false)}
        }
        onCancel={() => setOpenModal(false)}
      />
    </TokenContainer.Provider>
  )
};

export default {
  Context: TokenContainer,
  Provider: GitProvider,
};
