import { REF_BRANCH } from '../constants/common.constants';
import axios from './AxiosServices';
import { createNewBranch, commit, createMergeRequest } from './GitServices';

export const PATHS = [
  'en/translation.json',
  'jp/translation.json',
  'vi/translation.json'
];

// export const setToken = (token) => {
//   setAxiosToken(axios, token);
// }

export const getTranslations = async () => {
  return Promise.all(
    PATHS.map(filePath => {
      const path = encodeURIComponent(`src/locales/${filePath}`);
      return axios.get(`repository/files/${path}/raw?ref=${REF_BRANCH}`)
    })
  ).then(translations => translations).catch(err => err);
};

export const submitChanges = async ({ branchName, prTitle, prDescription }, translations) => {
  const newBranch = await createNewBranch(branchName);
  if (!newBranch) {
    return false;
  }

  const commitActions = PATHS.map((itemPath, itemIndex) => {
    return {
      'action': 'update',
      'file_path': `src/locales/${itemPath}`,
      'content': JSON.stringify(translations[itemIndex], null, 2)
    };
  });

  const newCommit = await commit(newBranch.name, 'Update translations', 'Update translations. Generated by web app', commitActions);
  if (!newCommit) {
    return false;
  }

  const newMR = await createMergeRequest(newBranch.name, prTitle, prDescription);
  if (!newMR) {
    return false;
  }
  return true;
};
