import React from 'react';

import { Input, Table } from 'antd';

const TranslationTable = ({ languages, translations, dataSource, onValueChange, loading }) => {
  const columns = [
    {
      title: 'Key',
      key: 'key',
      dataIndex: 'key',
      render: (_, item) => item.label || item.key,
    },
    ...languages.map((item, itemIndex) => ({
      title: item,
      key: item,
      render: item => {
        return (
          <Input value={translations[itemIndex][item.key]} onChange={(e) => onValueChange(itemIndex, item.key, e.target.value)} />
        );
      },
    }))
  ];
  return (
    <Table dataSource={dataSource} columns={columns} loading={loading} />
  );
};

export default TranslationTable;
