import React, { useContext, useEffect, useState } from 'react';
import TokenContainer from '../components/TokenContainer/TokenContainer';
import { getTranslations } from '../services/DashboardServices';
import TranslationForm from '../components/DashboardTranslationForm/DashboardTranslationForm';
import { notification } from 'antd';

const DashboardTab = () => {
  const { token, setToken, openTokenModal} = useContext(TokenContainer.Context);
  const [translations, setTranslations] = useState([]);
  useEffect(() => {
    const init = async () => {
      const translations = await getTranslations();
      if (translations instanceof Error) {
        notification.error({ message: 'Token is invalid' });
        setToken(null);
        openTokenModal();
      } else {
        setTranslations(translations);
      }
    }
    if (!token) openTokenModal();
    else init();
  }, [token]);


  return (
    <div>
      <TranslationForm translations={translations} />
    </div>
  );

};

export default DashboardTab;
