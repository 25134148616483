import React, { useEffect, useContext, useState, useMemo } from 'react';
import TokenContainer from '../components/TokenContainer/TokenContainer';
import TranslationTable from '../components/TranslationTable/TranslationTable';
import { COMMON_DUMMY } from '../constants/common.constants';
import { getCommonTranslations, updateTranslations } from '../services/CommonServices';
import { Button, Input, notification, Result, Spin } from 'antd';
import SubmitChangesModal from '../components/Modals/SubmitChangesModal';

const convertToTranslationDataSource = items => {
  const files = [];
  const rows = Object.keys(items).reduce((results, folderPath, folderIndex) => {
    files[folderIndex] = {};
    return [
      ...results,
      Object.keys(items[folderPath]).reduce((folderResults, filePath, fileIndex) => {
        files[folderIndex][filePath] = [];
        return {
          ...folderResults,
          ...Object.keys(items[folderPath][filePath]).reduce((fileResults, key) => {
            const formattedKey = key.replaceAll(" ", "");
            files[folderIndex][filePath].push(formattedKey);
            return {
              ...fileResults,
              [`${key}`.replaceAll(" ", "")]: items[folderPath][filePath][key].replaceAll("\"", ""),
            };
          }, {}),
        }
      }, []),
    ];
  }, []);
  return {
    files,
    rows,
  }
}

const CommonTabs = () => {
  const [success, setSuccess] = useState(false);
  const [translations, setTranslations] = useState([]);
  const [initialTranslations, setInitialTranslations] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [committing, setCommitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState('');
  const [files, setFiles] = useState({});
  const { token, openTokenModal } = useContext(TokenContainer.Context);

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      const translations = await getCommonTranslations();
      setLoading(false);
      const allItems = {};
      const items = Object.keys(translations).reduce((results, folderPath) => {
        const folder = translations[folderPath];
        allItems[folderPath.replaceAll()] = {};
        return {
          ...results,
          [folderPath]: folder.reduce((folderResults, fileContent) => {
            const fileKey = Object.keys(fileContent)[0];
            const rows = Object.values(fileContent)[0].split("\n").reduce((results, str) => {
              const chunks = str.split(": ");
              const key = chunks.shift();
              if (!key) {
                return results;
              }
              return {
                ...results,
                [key]: chunks.join(": "),
              }
            }, {});
            return {
              ...folderResults,
              [fileKey]: rows,
            };
          }, {}),
        }
      }, {});
      const { rows: convertedItems, files } = convertToTranslationDataSource(items);
      console.log(files);
      setFiles(files);
      setTranslations(convertedItems);
      setInitialTranslations(convertedItems);
      setDataSource(Object.keys(convertedItems[0]).map(key => ({ key })));
    }
    if (!token) openTokenModal();
    else init();
  }, [token]);


  const onValueChange = (itemIndex, key, value) => {
    setTranslations([
      ...translations.slice(0, itemIndex),
      {
        ...translations[itemIndex],
        [key]: value,
      },
      ...translations.slice(itemIndex + 1)
    ])
  };

  const onSubmit = async (values) => {
    setCommitting(true);
    const res = await updateTranslations(translations, files, values);
    setCommitting(false);
    if (res) {
      notification.success({
        message: 'Your changes is pushed and being review!'
      });
      setModalVisible(false);
      setSuccess(true);
    }
  };

  const filteredDataSource = useMemo(() => dataSource.filter(({ key }) => {
    if (!query) return true;
    return (key || "").toLowerCase().includes(query.toLowerCase()) ||
      translations.some(item => (item[key] || "").toLowerCase().includes(query.toLowerCase())) ||
      initialTranslations.some(item => (item[key] || "").toLowerCase().includes(query.toLowerCase()));
  }), [query, dataSource, initialTranslations, translations]);


  return ( 
    <Spin spinning={committing}>
      {success ? (
        <Result
          status="success"
          title="Your changes is pushed and being review!"
        />
      ) : (
        <div>
          <Input placeholder="Search..." value={query} onChange={e => setQuery(e.target.value)} />
          <TranslationTable loading={loading} dataSource={filteredDataSource} languages={Object.keys(COMMON_DUMMY)} translations={translations} onValueChange={onValueChange} />
          {translations.length ? <Button type="primary" onClick={() => setModalVisible(true)}>Submit</Button> : null}
          <SubmitChangesModal visible={modalVisible} loading={committing} onOk={onSubmit} onCancel={() => setModalVisible(false)} />
        </div>
      )}
    </Spin>
  );
};

export default CommonTabs;
