import React, { useMemo } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';

const TokenModal = ({ visible, onOk, onCancel }) => {
  const [form] = useForm();

  const onFormSubmit = async () => {
    const values = await form.validateFields();
    if (values && values.privateToken) {
      onOk(values.privateToken);
    }
  }
  const modalFooter = useMemo(() => {
    <div>
      <Button onClick={onFormSubmit} type="primary">OK</Button>
    </div>
  }, []);

  return (
    <Modal visible={visible} onOk={onFormSubmit} footer={modalFooter} title="Enter your private token" maskClosable={false} onCancel={onCancel}>
      <Form form={form}>
        <Form.Item label="Private token" name="privateToken">
          <Input type="password" />
        </Form.Item>
      </Form>
    </Modal>
  )
};

export default TokenModal;
