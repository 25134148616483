import _axios from 'axios';
import { GIT_BASE_URL } from '../constants/common.constants';


let token;
let projectId;

const axios = _axios.create({
  baseURL: `${GIT_BASE_URL}projects/`,
  headers: {
    "Content-Type": "application/json",
  },
});
axios.interceptors.response.use(
  (config) => {
    return Promise.resolve(config.data);
  },
  (error) => Promise.reject(error)
);

axios.interceptors.request.use(
  function (config) {
    if (token) {
      config.headers['PRIVATE-TOKEN'] = token;
      config.baseURL = `${GIT_BASE_URL}projects/${projectId}`
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const setAxiosToken = _token => token = _token;
export const setProjectId = _projectId => projectId = _projectId;


export default axios;
