import { Result, Form, Spin, Input, Button, notification } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';

import { PATHS, submitChanges } from '../../services/DashboardServices';
import { useForm } from 'antd/lib/form/Form';
import SubmitChangesModal from '../Modals/SubmitChangesModal';
import TranslationTable from '../TranslationTable/TranslationTable';

const TranslationForm = (props) => {
  const { translations: initialTranslations = [] } = props;
  const [committing, setCommitting] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [query, setQuery] = useState('');
  const [success, setSuccess] = useState(false);
  const [translations, setTranslations] = useState(initialTranslations);
  const [form] = useForm();

  useEffect(() => {
    setTranslations(initialTranslations);
  }, [initialTranslations]);

  const dataSource = useMemo(() => Object.keys(translations[0] || {}).map(key => ({ key })), [translations]);

  const filteredDataSource = useMemo(() => dataSource.filter(( { key }) => {
    if (!query) return true;
    return (key || "").toLowerCase().includes(query.toLowerCase()) ||
      translations.some(item => (item[key] || "").toLowerCase().includes(query.toLowerCase())) ||
      initialTranslations.some(item => (item[key] || "").toLowerCase().includes(query.toLowerCase()));
  }), [query, dataSource, initialTranslations, translations]);
  
  const onValueChange = (itemIndex, key, value) => {
    setTranslations([
      ...translations.slice(0, itemIndex),
      {
        ...translations[itemIndex],
        [key]: value,
      },
      ...translations.slice(itemIndex + 1)
    ])
  }

  const onFinish = (values) => {
    setModalVisible(true);
  }

  const onSubmit = async (values) => {
    setCommitting(true);
    const res = await submitChanges(values, translations);
    setCommitting(false);
    if (!res) return;
    notification.success({
      message: 'Your changes is pushed and being review!'
    });
    setModalVisible(false);
    setSuccess(true);
  };

  return (
    <Spin spinning={isEmpty(translations)}>
      {success ? (
        <Result
          status="success"
          title="Your changes is pushed and being review!"
        />
      ) : (
        <Form form={form} onFinish={onFinish}>
          <Input placeholder="Search..." value={query} onChange={e => setQuery(e.target.value)} />
          <TranslationTable dataSource={filteredDataSource} languages={PATHS} translations={translations} onValueChange={onValueChange} />
          <div>
            <Button htmlType="submit" type="primary">SUBMIT</Button>
          </div>
        </Form>
      )}
      <SubmitChangesModal visible={modalVisible} loading={committing} onOk={onSubmit} onCancel={() => setModalVisible(false)} />
    </Spin>
  );
};

export default TranslationForm;
