import { flatten, pick, uniq } from 'lodash';
import { REF_BRANCH } from '../constants/common.constants';
import axios from './AxiosServices';
import { commit, createMergeRequest, createNewBranch } from './GitServices';

export const getLocales = async () => {
  return axios({
    method: 'GET',
    url: 'repository/tree',
    params: {
      recursive: false,
      path: encodeURIComponent('services/common/locales'),
      ref: REF_BRANCH,
    }
  });
}

const PATHS = [
  'services/common/locales/en-US',
  'services/common/locales/ja-JP',
  'services/common/locales/vi-VN',
];

export const getLocaleFiles = async path => {
  return axios({
    method: 'GET',
    url: 'repository/tree',
    params: {
      recursive: true,
      path,
      ref: REF_BRANCH,
    }
  });
}

export const getFileContent = async filePath => {
  const file = await axios({
    method: 'GET',
    url: `repository/files/${encodeURIComponent(filePath)}/raw`,
    params: {
      ref: REF_BRANCH,
    }
  });
  return {
    [filePath]: file,
  };
}

export const getCommonTranslations = async () => {
  const queue = PATHS.map(path => getLocaleFiles(path));
  const folders = await Promise.all(queue);
  const mainQueue = folders.map((files) => {
    return Promise.all(files.map(file => getFileContent(file.path)));
  });
  const allFiles = await Promise.all(mainQueue);
  console.log(allFiles);

  return PATHS.reduce((results, path, pathIndex) => {
    return {
      ...results,
      [path]: allFiles[pathIndex],
    }
  }, {});
};

const convertToYMLContent = object => {
  return Object.keys(object).reduce((results, key) => {
    if (!results) return `${key}: "${object[key]}"`;
    return `${results}\n${key}: "${object[key]}"`;
  }, '');
};

export const updateTranslations = async (translations, folders, { branchName, prTitle, prDescription }) => {
  const allFiles = uniq(folders.reduce((results, files) => {
    return [
      ...results,
      ...Object.keys(files).map(filePath => filePath.split('/').pop()),
    ];
  }, []));
  
  const newBranch = await createNewBranch(branchName);
  if (!newBranch) {
    return false;
  }

  const commitActions = folders.map((files, fileIndex) => {
    const translationsByLanguage = translations[fileIndex];
    const filePathPrefix = Object.keys(files)[0].split('/');
    filePathPrefix.pop();
    const prefix = filePathPrefix.join('/');
    return allFiles.map((fileName, index) => {
      const filePath = `${prefix}/${fileName}`;
      return {
        'action': Object.keys(files).includes(filePath) ? 'update' : 'create',
        'file_path': filePath,
        'content': convertToYMLContent(pick(translationsByLanguage, files[filePath]))
      };
    });
  });

  const newCommit = await commit(newBranch.name, 'Update translations', 'Update translations. Generated by web app', flatten(commitActions));
  if (!newCommit) {
    return false;
  }

  const newMR = await createMergeRequest(newBranch.name, prTitle, prDescription);
  if (!newMR) {
    return false;
  }
  return true;
};
