import React from 'react';
import { Modal, Input, Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import TextArea from 'antd/lib/input/TextArea';

const SubmitChangesModal = ({ visible, loading, onCancel, onOk }) => {
  const [form] = useForm();

  const onSubmit = async () => {
    const values = await form.validateFields();
    if (values) {
      onOk(values);
    }
  }
  return (
    <Modal title="Submit changes" destroyOnClose onCancel={onCancel} onOk={onSubmit} visible={visible} okButtonProps={{ loading }}>
      <Form layout="vertical" form={form}>
        <Form.Item name="branchName" label="Branch name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="prTitle"  label="Pull request title" required rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="prDescription" label="Pull request description">
          <TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SubmitChangesModal;
